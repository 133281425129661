import * as Yup from 'yup';
import { v4 as uuid } from 'uuid';
import { Investment, InvestmentType } from '~/src/hooks/useStacksAPI';
import { InvestmentSchema } from './types';

const DEFAULTS: InvestmentSchema = {
  id: '',
  user: '',
  city: '',
  icon: 'house-1',
  type: InvestmentType.none,
  address: '',
  state: '',
  zip: '',
  country: 'United Sates',
  neighborhood: '',
  lotSize: 0.25,
  year: 1980,
  units: {
    items: [
      {
        id: uuid(),
        name: 'Unit 1',
        bedrooms: 0,
        bathroom: 0,
        sqft: 0,
        pets: false
      }
    ]
  },
  sqft: 0,
  meta: { built: false }
};

export const init = (userId: string = '', i: Investment = DEFAULTS): InvestmentSchema => {
  return {
    id: i.id || DEFAULTS.id,
    user: userId || DEFAULTS.user,
    icon: i.icon || DEFAULTS.icon,
    city: i.city || DEFAULTS.city,
    type: i.type || DEFAULTS.type,
    address: i.address || DEFAULTS.address,
    state: i.state || DEFAULTS.state,
    zip: i.zip || DEFAULTS.zip,
    country: i.country || DEFAULTS.country,
    neighborhood: i.neighborhood || DEFAULTS.neighborhood,
    lotSize: i.lotSize || DEFAULTS.lotSize,
    year: i.year || DEFAULTS.year,
    units: i.units || DEFAULTS.units,
    sqft: i.sqft || DEFAULTS.sqft,
    meta: { built: false }
  };
};

const validation = Yup.object({
  city: Yup.string().required('City is required'),
  type: Yup.string().required('Type is required'),
  address: Yup.string().required('Address is required'),
  state: Yup.string().required('State is required'),
  zip: Yup.string().required('ZIP is required'),
  neighborhood: Yup.string().optional(),
  country: Yup.string().optional(),
  lotSize: Yup.number().required('Lot size is required'),
  year: Yup.number()
    .required('Year is required')
    .min(1900, 'Year must be at least 1900')
    .max(new Date().getFullYear(), `Year must be at most ${new Date().getFullYear()}`),
  units: Yup.object({
    items: Yup.array()
      .of(
        Yup.object({
          name: Yup.string().required('Unit name is required'),
          bedrooms: Yup.number()
            .required('Number of bedrooms is required')
            .min(0, 'Bedrooms cannot be less than 0'),
          bathroom: Yup.number()
            .required('Number of bathrooms is required')
            .min(0, 'Bathrooms cannot be less than 0'),
          pets: Yup.boolean().required('Pets information is required'),
          sqft: Yup.number()
            .required('Square footage is required')
            .min(0, 'Square footage cannot be less than 0')
        })
      )
      .min(0, 'At least one unit is required')
  }),
  sqft: Yup.number()
    .required('Total square footage is required')
    .min(0, 'Total square footage cannot be less than 0')
});

export default { init, validation };
